<template>
<div>
  <div class="bg-white">
    <div class="pt-8 overflow-hidden lg:relative py-6">
      <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
        <div>
          <a href='/' class='inline-flex'>
            <img class="h-11 w-auto" src="/img/signals.png"
              alt="Workflow" />
            <div class='text-blue-900 font-semibold text-xl pt-2 pl-1'>Signals</div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-blue-900">
    <div class="pt-12 sm:pt-16 lg:pt-24">
      <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
          <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
            Our Plans
          </h2>
          <p class="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            Trading software for everyone
          </p>
          <p class="text-xl text-gray-300">
            Investing and trading cryptocurrencies just got a whole lot easier. </p>
        </div>
      </div>
    </div>
    <div class="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
      <div class="relative">
        <div class="absolute inset-0 h-3/4 bg-blue-900" />
        <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
            <div v-for="tier in tiers" :key="tier.name" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                <div>
                  <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-standard">
                    {{ tier.name }}
                  </h3>
                </div>
                <div class="mt-4 flex items-baseline text-6xl font-extrabold">
                  ${{ tier.priceMonthly }}
                  <span class="ml-1 text-2xl font-medium text-gray-500">
                    /mo
                  </span>
                </div>
                <p class="mt-5 text-lg text-gray-500">
                  {{ tier.description }}
                </p>
              </div>
              <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                <ul role="list" class="space-y-4">
                  <li v-for="feature in tier.features" :key="feature" class="flex items-start">
                    <div class="flex-shrink-0">
                      <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      {{ feature }}
                    </p>
                  </li>
                </ul>
                <div class="rounded-md shadow">
                  <a :href="tier.href" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900" aria-describedby="tier-standard">
                    {{ tier.callToAction }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
        <div class="max-w-md mx-auto lg:max-w-5xl">
          <div class="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
            <div class="flex-1">
              <div>
                <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                  Discounted
                </h3>
              </div>
              <div class="mt-4 text-lg text-gray-600">Get full access to all of the professional plan features for solo investors that trade less than $25k anually for <span class="font-semibold text-gray-900">$49/mth</span>.</div>
            </div>
            <div class="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
              <a href="#" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                Buy Discounted License
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline'

const tiers = [
  {
    name: 'Personal',
    href: '/signup',
    priceMonthly: 0,
    description: 'Easy to get started and always free for personal use',
    callToAction: 'Select free',
    features: [
      'Automated analysis of 4 cryptocurrencies',
      'Get notified when currencies signal up or down',
      'Live news and sentiment analysis',
      'Export historical signal events to CSV',
    ],
  },
  {
    name: 'Professional',
    href: '/signup',
    priceMonthly: 79,
    description: 'Simple to use tools and API access',
    callToAction: 'Select professional',
    features: [
      "Automated analysis of 100's cryptocurrencies",
      'All the features of the personal plan',
      'API access to cryptocurrency sentiment data',
      'API access for all market signal data',
    ],
  },
]

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      tiers,
    }
  },
}
</script>


<style scoped src="@/assets/tailwind.css">
</style>